@function map-get-or-default($map, $key, $default-value) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  }

  @return $default-value;
}

@function map-get-strict($map, $key) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  }

  @error "'#{$key}' is not in the map: #{$map}.";
}

$animations-speed-map: (
  controllers: 100ms,
  popovers-in: 150ms,
  popovers-out: 75ms,
  default-in: 250ms,
  default-out: 200ms,
);

$animations-ease-map: (
  default: cubic-bezier(0.4, 0, 0.2, 1),
  in: cubic-bezier(0, 0, 0.2, 1),
  out: cubic-bezier(0.4, 0, 1, 1),
);

@function get-animation-speed($speed) {
  @return map-get-or-default($animations-speed-map, $speed, $speed);
}

@function get-animation-ease($ease: default) {
  @return map-get-or-default($animations-ease-map, $ease, $ease);
}

@function get-animation($property, $speed, $ease: default) {
  @if $property == speed or $property == ease {
    // Just to understand the mapping with the deprecated function.
    $type: $property;
    $key: $speed;

    @warn "`get-animation(#{$type}, #{$key})` is deprecated, use the expanded `get-animation` syntax, or `get-animation-speed` and `get-animation-ease`.";

    @if $type == speed {
      @return get-animation-speed($key);
    }

    @return get-animation-ease($key);
  }

  @return $property get-animation-speed($speed) get-animation-ease($ease);
}

$typography-sans-serif-title: 'Open Sans', 'Arial';
$typography-sans-serif-body: 'Roboto', 'Arial';
$typography-monospace-body: 'Hack', 'Monospace';

// WARNING
// Do not change these values without changing the one in the JavaScript file.
$typography-map: (
  display: (
    font-family: $typography-sans-serif-title,
    font-size: 32px,
    font-weight: 300,
  ),
  header: (
    font-family: $typography-sans-serif-title,
    font-size: 20px,
    font-weight: 700,
  ),
  sub-header: (
    font-family: $typography-sans-serif-title,
    font-size: 16px,
    font-weight: 600,
  ),
  body: (
    font-family: $typography-sans-serif-body,
    font-size: 14px,
    font-weight: 400,
  ),
  body-display: (
    font-family: $typography-sans-serif-body,
    font-size: 12px,
    font-weight: 300,
    text-transform: uppercase,
  ),
  body-enphasis: (
    font-family: $typography-sans-serif-body,
    font-size: 14px,
    font-weight: 500,
  ),
  caption: (
    font-family: $typography-sans-serif-body,
    font-size: 12px,
    font-weight: 400,
  ),
  button: (
    font-family: $typography-sans-serif-title,
    font-size: 14px,
    font-weight: 700,
    text-transform: uppercase,
  ),
  code: (
    font-family: $typography-monospace-body,
    font-size: 14px,
    font-weight: 400,
  ),
  code-emphasis: (
    font-family: $typography-monospace-body,
    font-size: 14px,
    font-weight: 700,
  ),
);

@mixin typography($preset: body) {
  @each $key, $value in map-get-strict($typography-map, $preset) {
    #{$key}: $value;
  }
}

@mixin text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// WARNING
// Do not change these values without changing the one in the JavaScript file.
$colors: (
  'purple': (
    base: #da1379,
  ),
  'green': (
    base: rgb(63, 212, 139),
    desaturate: rgba(41, 135, 89, 1),
    active: rgba(63, 212, 139, 0.05),
    fade: rgba(63, 212, 139, 0.15),
  ),
  'blue': (
    light-1: rgb(34, 52, 70),
    light-2: rgb(46, 70, 93),
    base: rgb(22, 44, 69),
    dark: rgb(15, 31, 49),
    dark-fade: rgba(15, 31, 49, 0.9),
    fade: rgba(15, 31, 49, 0.15),
    eggshell: rgba(0, 188, 213, 1),
  ),
  'grey': (
    hover: rgb(246, 246, 246),
    light: rgb(158, 158, 158),
    mid: rgb(111, 111, 111),
    dark: rgb(51, 51, 51),
    background: rgb(249, 249, 249),
  ),
  'white': (
    base: rgb(255, 255, 255),
    fade: rgba(255, 255, 255, 0.15),
  ),
  'black': (
    base: rgb(0, 0, 0),
    fade: rgba(0, 0, 0, 0.15),
  ),
  'red': (
    base: rgb(215, 6, 1),
    fade: rgba(215, 6, 1, 0.15),
    light: rgb(235, 130, 128),
  ),
  'orange': (
    base: rgb(255, 160, 55),
    fade: rgba(255, 160, 55, 0.15),
  ),
  'yellow': (
    base: rgb(255, 219, 3),
  ),
);

@function get-color($color, $variant: base) {
  // Make sure the parameter is a string and not a color.
  $color: '#{$color}';

  $index: str-index($color, '-');

  @if $index != null {
    $new-color: str-slice($color, $index + 1);
    @warn 'The color prefix in `get-color` are deprecated, "#{$color}" is now "#{$new-color}".';
    $color: $new-color;
  }

  @if $color == 'blue' and $variant == 'dark-90' {
    $variant: dark-fade;
    @warn 'The variant "dark-90" of the "blue" color is deprecated, please use "dark-fade" instead.';
  }

  @return map-get-strict(map-get-strict($colors, $color), $variant);
}

$elevations: (
  (/* Use default values. */),
  (
    offset-y: 0,
    blur-radius: 2,
    color-opacity: 0.16,
  ),
  (
    offset-y: 1,
    blur-radius: 6,
    color-opacity: 0.1,
  ),
  (
    offset-y: 2,
    blur-radius: 6,
    color-opacity: 0.23,
  ),
  (
    offset-y: 4,
    blur-radius: 9,
    color-opacity: 0.26,
  ),
  (
    offset-y: 6,
    blur-radius: 12,
    color-opacity: 0.29,
  ),
  (
    offset-y: 8,
    blur-radius: 15,
    color-opacity: 0.3,
  )
);

@mixin elevation($level: 3) {
  // Lists start at index 1...
  @if $level + 1 > length($elevations) {
    @error "There are only #{length($elevations)} levels of elevation, received: #{$level}";
  }

  $elevation: nth($elevations, $level + 1);
  $offset-x: map-get-or-default($elevation, offset-x, 0);
  $offset-y: map-get-or-default($elevation, offset-y, 0);
  $blur-radius: map-get-or-default($elevation, blur-radius, 0);
  $spread-radius: map-get-or-default($elevation, spread-radius, 0);
  $color-opacity: map-get-or-default($elevation, color-opacity, 0);

  box-shadow: #{$offset-x}px #{$offset-y}px #{$blur-radius}px #{$spread-radius}px
    rgba(0, 0, 0, $color-opacity);
}
