.Chip--yellow {
  background-color: get-color('yellow', base);
  color: get-color('grey', dark);
}

.Chip--yellow--active,
.Chip--yellow--clickable:hover,
.Chip--yellow:focus {
  background-color: mix(
    get-color('white', base),
    get-color('yellow', base),
    40%
  );
}
