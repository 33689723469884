.content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  height: 90px;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: 1px solid $color-light-grey;

  &-echantillon {
    @extend .content__header;
    justify-content: space-around;
  }

  .separator {
    display: flex;
    margin: 2em;
    height: 100%;
    border-left: 1px solid $color-light-grey;
  }

  &__study {
    display: inline-flex;
    align-items: center;

    &-logo {
      & > img {
        height: 64px;
      }
    }

    &-title {
      text-transform: capitalize;
      padding-left: 0.75em;
      font-size: 32px;
      font-weight: 300;
      letter-spacing: 2px;
      color: $color-dark-grey;
    }
  }

  &--search {
    display: flex;
    max-width: 328px;
  }

  &--actions {
    display: flex;
    margin-left: auto;
  }

  .versus {
    text-align: center;
    width: 5vw;
    max-width: 40px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-dark-grey;
  }
}

.averageRows {
  &--sticky {
    z-index: 2;
    position: sticky;
    top: 48px;
    background-color: $color-white;
    // border-bottom: 1px solid $color-shadow-grey;
  }
}
