@import '../../scss/var/_colors.module.scss';

.table__header {
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  padding-left: 8px;
  height: 41px;

  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    cursor: pointer;
    color: $color-dark-grey;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &__info {
    // Common to all lists (Rank, Artists, Title)
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--label {
      min-width: 0;
      > p {
        @extend .label;
      }
      &:first-child {
        flex: 0.12;
      }
      flex: 0.2;
      &--ranking {
        &:first-child {
          flex: 0.05;
        }
        flex: 0.09;
      }
      &--title {
        &:first-child {
          flex: 1;
        }
      }
    }

    &-details {
      flex: 4;
    }

    &-ranking {
      flex: 20;
    }

    &-compare {
      flex: 7;
      &--simple {
        flex: 10;
      }
    }
  }

  &__values {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-details {
      flex: 9;
    }

    &-study {
      flex: 4;
    }

    &-ranking {
      flex: 1;
    }

    &-compare {
      flex: 1;
    }

    &--label {
      min-width: 0;
      flex-basis: 50%;
      > p {
        text-align: center;
        @extend .label;
      }
      flex: 1;
    }
  }

  &-sticky {
    position: sticky;
    top: 48px;
    padding-top: 15px;
    background-color: $color-white;
    border-bottom: 1px solid $color-shadow-grey;
  }

  &__select {
    font-family: 'Open Sans';
    font-weight: 600;
    padding-right: 1em;
    font-size: 14px;
    color: $color-dark-grey;
    letter-spacing: 0;
    text-align: right;
    line-height: 24px;
    cursor: pointer;
  }

  &__right {
    display: inline-flex;
  }

  &__results {
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.83;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: $color-dark-grey;
  }

  &__columns {
    padding-right: 0.75em;
    display: inline-flex;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    color: $color-dark-grey;
    letter-spacing: 0;
    text-align: right;
    line-height: 24px;
    cursor: pointer;

    i {
      display: block;
      padding-left: 0.25em;
      line-height: 24px;
    }

    .dropdown {
      margin-top: 28px;
      text-align: left;
    }
  }
}

.head-values {
  // Moyennes, Ecart type, etc.
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid $color-light-grey;
  line-height: 46px;

  &__info {
    display: flex;
    flex: 4;
    font-size: 14px;

    .xl {
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__values {
    display: inline-flex;
    flex: 9;
    padding-left: 8px;
    padding-right: 8px;

    span {
      flex: 1;
      text-align: center;
      font-size: 14px;
      color: $color-black;
      font-family: 'Fjalla One';
    }

    .xl {
      font-size: 20px;
      font-family: 'Fjalla One';
      color: $color-blue;
    }
  }
}
