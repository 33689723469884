$OutlinedInput-lineHeight: 21px;
$OutlinedInput-heightMedium: 48px;
$OutlinedInput-heightSmall: 36px;

$OutlinedInput-horizontalSpacing: 16px;

$OutlinedInput-verticalSpacing-heightMedium: (
    $OutlinedInput-heightMedium - $OutlinedInput-lineHeight
  ) / 2;

$OutlinedInput-verticalSpacing-heightSmall: (
    $OutlinedInput-heightSmall - $OutlinedInput-lineHeight
  ) / 2;
