.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 175px 0;

  .login__logo {
    width: 221px;
    height: 49px;
  }
  .login__card {
    width: 386px;
    height: 491px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.1);
    margin: 57px auto;
    padding: 27px 27px 0 27px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .card__title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      color: #162c45;
    }
    .card__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }
    .form__group {
      display: flex;
      flex-direction: column;
      margin-bottom: 17px;
    }
    .form__group--space-betwen {
      flex-direction: row;
      justify-content: space-between;
      margin: 17px 0;
    }
    .form__group--space-betwen label {
      color: #797979;
      font-size: 14px;
    }
    .form__group--space-betwen input {
      margin-right: 4px;
    }
    .form__group--space-betwen a {
      font-size: 14px;
      color: #162c45;
    }
    .form__input {
      position: relative;
    }
    .form__input input {
      position: relative;
      width: 100%;
      padding: 8px 0 11px 10px;
      margin-bottom: 17px;
      border-radius: 4px;
      border: solid 1px #f2f2f2;
      color: #8c8c8c;
      font-size: 16px;
      // opacity: 0.75;
    }
    .form__input .icon {
      position: absolute;
      color: #8c8c8c;
      top: 8px;
      right: 8px;
    }
    .form__label {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      color: #4a4a4a;
      margin-bottom: 5px;
    }
    .is_error input {
      border-color: #d70601;
    }
    .is_error span {
      color: #d70601;
    }

    .is_error label {
      color: #d70601;
    }

    .card__signIn--google {
      width: 64%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      border-top: solid 1px #f0f0f0;
      padding: 24px 96px 35px 97px;
    }
    .card__signIn--google img {
      width: 206px;
      height: 48px;
      cursor: pointer;
    }
  }
  .login__contact {
    color: #8c8c8c;
    .link {
      color: #162c45;
    }
  }
  .help-block {
    color: #d70601;
    position: relative;
    top: -16px;
    font-size: 12px;
  }
}
