.Chip--white {
  background-color: get-color('white', base);
  color: get-color('grey', mid);
}

.Chip--white--active,
.Chip--white--clickable:hover,
.Chip--white:focus {
  background-color: mix(
    get-color('white', base),
    get-color('black', base),
    70%
  );
}
