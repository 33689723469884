.AutoSizeInput {
  position: relative;
  overflow: hidden;
  min-width: 0;
}

.AutoSizeInput__nativeInput {
  margin: 0;
  border: none;
  min-width: 0;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  outline: none;
  width: 100%;
  min-width: inherit;
  max-width: 100%;
  height: 100%;
}

.AutoSizeInput__ruler {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
}
