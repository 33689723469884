// @import 'outlined-input.utils.scss';
$Adornment-size: 24px;

$OutlinedInput-bgWhite-base: get-color('white', base);
$OutlinedInput-bgGrey-base: mix(rgb(51, 51, 51), transparent, 6%);
$OutlinedInput-bgGrey-hover: mix($OutlinedInput-bgGrey-base, transparent, 50%);
$OutlinedInput-borderSpacing: 6px;

@function OutlinedInput-border($width, $color) {
  @return inset 0 0 0 $width $color;
}

.OutlinedInput {
  position: relative;
  border-radius: 4px;
  min-width: 160px;
  width: 100%;
  display: inline-flex;
  align-items: stretch;
  background-color: $OutlinedInput-bgGrey-base;
  color: rgb(51, 51, 51);
  font-size: 14px;
  line-height: $OutlinedInput-lineHeight;
  outline: none;
  transition: get-animation(background-color, controllers),
    get-animation(box-shadow, controllers);
}

.OutlinedInput:hover {
  background-color: $OutlinedInput-bgGrey-hover;
}

.OutlinedInput--content-type-text {
  cursor: text;
}

.OutlinedInput--content-type-action {
  cursor: pointer;
}

.OutlinedInput--size-medium {
  min-height: $OutlinedInput-heightMedium;
}

.OutlinedInput--size-small {
  min-height: $OutlinedInput-heightSmall;
}

.OutlinedInput--multiline-adornments {
  flex-direction: column;
}

%OutlinedInput--outlined {
  background-color: $OutlinedInput-bgWhite-base;
}

%OutlinedInput--outlined:hover {
  background-color: $OutlinedInput-bgWhite-base;
}

.OutlinedInput--filled {
  @extend %OutlinedInput--outlined;
  box-shadow: OutlinedInput-border(1px, get-color('grey', light));
}

.OutlinedInput--filled:hover {
  box-shadow: OutlinedInput-border(1px, get-color('grey', mid));
}

.OutlinedInput--focus,
.OutlinedInput--focus:hover {
  @extend %OutlinedInput--outlined;
  box-shadow: OutlinedInput-border(2px, get-color('purple', base));
}

.OutlinedInput--error,
.OutlinedInput--error:hover {
  @extend %OutlinedInput--outlined;
  box-shadow: OutlinedInput-border(2px, get-color('red', base));
}

.OutlinedInput--disabled {
  background-color: $OutlinedInput-bgGrey-base;
  box-shadow: OutlinedInput-border(1px, get-color('grey', light));
  cursor: not-allowed;
}

.OutlinedInput--disabled:hover {
  background-color: $OutlinedInput-bgGrey-hover;
  box-shadow: OutlinedInput-border(1px, get-color('grey', mid));
}

.OutlinedInput--disabled.OutlinedInput--filled:hover {
  background-color: $OutlinedInput-bgGrey-base;
}

.OutlinedInput__label {
  position: absolute;
  top: 50%;
  left: $OutlinedInput-horizontalSpacing;
  transform: translateY(-50%);
  color: get-color('grey', light);
  user-select: none;
  pointer-events: none;
  transition: get-animation(top, controllers, out),
    get-animation(color, controllers, out),
    get-animation(font-size, controllers, out);
}

%OutlinedInput__label--multiline {
  transform: translateY(0);
}

.OutlinedInput__label--multiline-medium {
  @extend %OutlinedInput__label--multiline;
  top: $OutlinedInput-verticalSpacing-heightMedium;
}

.OutlinedInput__label--multiline-small {
  @extend %OutlinedInput__label--multiline;
  top: $OutlinedInput-verticalSpacing-heightSmall;
}

%OutlinedInput__label--on-top {
  top: 0;
  transform: translateY(calc(-50% - 1px));
  font-size: 12px;
  transition: get-animation(top, controllers, in),
    get-animation(color, controllers, in),
    get-animation(font-size, controllers, in);
}

.OutlinedInput__label--filled {
  @extend %OutlinedInput__label--on-top;
}

.OutlinedInput__label--focus {
  @extend %OutlinedInput__label--on-top;
  color: get-color('purple', base);
}

.OutlinedInput__label--error.OutlinedInput__label--filled,
.OutlinedInput__label--error.OutlinedInput__label--focus {
  color: get-color('red', base);
}

.OutlinedInput__label--disabled.OutlinedInput__label--error {
  color: get-color('grey', light);
}

.OutlinedInput__dummy-label {
  position: absolute;
  top: 0;
  left: $OutlinedInput-horizontalSpacing - $OutlinedInput-borderSpacing;
  transform: scaleX(0);
  transform-origin: left;
  height: 2px;
  padding: 0 $OutlinedInput-borderSpacing;
  background-color: $OutlinedInput-bgWhite-base;
  color: transparent;
  font-size: 12px;
  user-select: none;
  pointer-events: none;
  transition: get-animation(transform, controllers, out);
}

.OutlinedInput__dummy-label--focus,
.OutlinedInput__dummy-label--filled {
  transform: scaleX(1);
  transition: get-animation(transform, controllers, in);
}

.OutlinedInput__dummy-label--disabled.OutlinedInput__dummy-label--filled {
  // Simulate a 1px border by using the same color as the disabled background
  // for the bottom 1px.
  border-bottom: 1px solid $OutlinedInput-bgGrey-base;
}

.OutlinedInput__helper {
  position: absolute;
  top: 100%;
  left: $OutlinedInput-horizontalSpacing;
  right: $OutlinedInput-horizontalSpacing;
  color: get-color('grey', light);
  opacity: 0;
  user-select: none;
  pointer-events: none;
  font-size: 12px;
  transition: get-animation(opacity, controllers),
    get-animation(color, controllers);
}

%OutlinedInput__helper--visible {
  opacity: 1;
}

.OutlinedInput__helper--focus {
  @extend %OutlinedInput__helper--visible;
  color: get-color('green', base);
}

.OutlinedInput__helper--error {
  @extend %OutlinedInput__helper--visible;
  color: get-color('red', base);
}

.OutlinedInput__helper--disabled {
  @extend %OutlinedInput__helper--visible;
  color: get-color('grey', light);
}

.OutlinedInput:hover .OutlinedInput__helper--show-on-hover {
  @extend %OutlinedInput__helper--visible;
}

.OutlinedInput__content {
  position: relative;
  margin: 0 $OutlinedInput-horizontalSpacing;
  flex: 1;
  min-width: 0;
  display: inline-flex;
  align-items: stretch;
  overflow: hidden;
}

.OutlinedInput__content--multiline-adornments-medium {
  margin: $OutlinedInput-verticalSpacing-heightMedium 0;
}

.OutlinedInput__content--multiline-adornments-small {
  margin: $OutlinedInput-verticalSpacing-heightSmall 0;
}

.OutlinedInput__content--with-adornments {
  margin-right: 0;
  margin-bottom: 0;
}

.OutlinedInput__adornments {
  margin-left: $OutlinedInput-horizontalSpacing / 2;
  margin-right: $OutlinedInput-horizontalSpacing;
  align-self: center;
  flex: none;
  display: inline-flex;
  align-items: center;
  color: get-color('grey', mid);
}

%OutlinedInput__adornments--multiline {
  align-self: flex-end;
}

.OutlinedInput__adornments--multiline-medium {
  @extend %OutlinedInput__adornments--multiline;
  padding-bottom: (($OutlinedInput-heightMedium - $Adornment-size) / 2);
}

.OutlinedInput__adornments--multiline-small {
  @extend %OutlinedInput__adornments--multiline;
  padding-bottom: (($OutlinedInput-heightSmall - $Adornment-size) / 2);
}
