$Toggle-width: 40px;
$Toggle-height: 20px;

%Toggle-elements {
  position: absolute;
  top: 50%;
  left: 0;
  content: '';
  transition: get-animation(all, controllers, default);
}

.Toggle {
  position: relative;
  width: $Toggle-width;
  height: $Toggle-height;
}

.Toggle__input {
  margin: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  opacity: 0;
  outline: none;
  cursor: pointer;
}

.Toggle__input:disabled {
  cursor: not-allowed;
}

.Toggle__span {
  user-select: none;
  pointer-events: none;
}

.Toggle__span::before {
  @extend %Toggle-elements;
  transform: translateY(-50%);
  width: $Toggle-width;
  height: 14px;
  background-color: mix(
    get-color('grey', light),
    get-color('white', base),
    70%
  );
  border-radius: 8px;
}

.Toggle__span::after {
  @extend %Toggle-elements;
  transform: translate(0, -50%);
  width: $Toggle-height;
  height: $Toggle-height;
  background-color: mix(
    get-color('grey', light),
    get-color('white', base),
    20%
  );
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
}

.Toggle__input:disabled + .Toggle__span {
  opacity: 0.5;
}

.Toggle__input:checked + .Toggle__span::before {
  background-color: mix(get-color('purple', base), transparent, 50%);
}

.Toggle__input:checked + .Toggle__span::after {
  background-color: get-color('purple', base);
  transform: translate(100%, -50%);
}
