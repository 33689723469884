.Chip--purple {
  background-color: get-color('purple', base);
  color: get-color('white', base);
}

.Chip--purple--active,
.Chip--purple--clickable:hover,
.Chip--purple:focus {
  background-color: mix(
    get-color('white', base),
    get-color('purple', base),
    30%
  );
}
