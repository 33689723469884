.Chip--light-grey {
  background-color: get-color('grey', hover);
  color: get-color('grey', dark);
}

.Chip--light-grey--active,
.Chip--light-grey--clickable:hover,
.Chip--light-grey:focus {
  background-color: mix(
    get-color('grey', hover),
    get-color('black', base),
    90%
  );
}
