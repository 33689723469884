.Card {
  border-radius: 3px;
  background-color: get-color('white', base);
}

@for $i from 0 through length($elevations) - 1 {
  .Card--elevation#{$i} {
    @include elevation($i);
  }
}
