//Colors
/* Back*/
$color-black: rgb(27, 27, 27);
$color-dark-grey: rgb(51, 51, 51);
$color-mid-grey: rgb(127, 127, 127);
$color-light-grey: rgb(244, 244, 244);
$color-hover: rgb(250, 250, 250);
$color-shadow-grey: rgba(0, 0, 0, 0.16);
$color-input: rgba(51, 51, 51, 0.06);
$color-white: rgb(255, 255, 255);

/*Main*/
$color-purple: rgb(138, 49, 127);
$color-pumpkin-orange: rgb(242, 138, 21);

/*Secondary*/
$color-blueberry: rgb(109, 56, 145);
$color-pink: rgb(218, 19, 121);
$color-yellow: rgb(255, 192, 56);
$color-green: rgb(49, 138, 71);
$color-green-light: rgb(121, 138, 49);
$color-orange: rgb(255, 123, 68);
$color-red: rgb(242, 21, 40);
$color-lightish-blue: rgb(56, 109, 255);

/* New */
$color-blue: rgb(30, 56, 91);
$color-dust: rgb(168, 152, 155);
$color-orange: rgb(250, 157, 90);
$color-cyan: rgb(207, 249, 243);

/* New gradients */
$color-gradient-1: #f3884d;
$color-gradient-2: #dc656b;
$color-gradient-3: #a95481;
$color-gradient-4: #6d4e80;
$color-gradient-5: #3c4769;
$color-gradient-6: #253846;

$color-map-gradient-a-1: #a8989b;
$color-map-gradient-a-2: #917b87;
$color-map-gradient-a-3: #746178;
$color-map-gradient-a-4: #4f4b6a;
$color-map-gradient-a-5: #1e385b;

$color-map-gradient-b-1: #a8989b;
$color-map-gradient-b-2: #c39194;
$color-map-gradient-b-3: #da8b84;
$color-map-gradient-b-4: #eb876c;
$color-map-gradient-b-5: #f3884d;

// the :export directive is the magic sauce for webpack
:export {
  colorblue: $color-blue;
  colordust: $color-dust;
  colororange: $color-orange;
  colorcyan: $color-cyan;

  colorgradient1: $color-gradient-1;
  colorgradient2: $color-gradient-2;
  colorgradient3: $color-gradient-3;
  colorgradient4: $color-gradient-4;
  colorgradient5: $color-gradient-5;
  colorgradient6: $color-gradient-6;

  colormapgradienta1: $color-map-gradient-a-1;
  colormapgradienta2: $color-map-gradient-a-2;
  colormapgradienta3: $color-map-gradient-a-3;
  colormapgradienta4: $color-map-gradient-a-4;
  colormapgradienta5: $color-map-gradient-a-5;
  colormapgradientb1: $color-map-gradient-b-1;
  colormapgradientb2: $color-map-gradient-b-2;
  colormapgradientb3: $color-map-gradient-b-3;
  colormapgradientb4: $color-map-gradient-b-4;
  colormapgradientb5: $color-map-gradient-b-5;
}
