$ListItem-textColor: get-color('grey', dark);
$ListItem-textColorDisabled: mix(get-color('grey', dark), transparent, 50%);

.ListItem {
  @extend %reset-button-styles;
  @extend %reset-anchor-styles;

  line-height: 1.57;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $ListItem-textColor;
  font-size: 14px;
  cursor: pointer;
  transition: get-animation(background-color, controllers, default),
    get-animation(color, controllers, default);
}

.ListItem--highlighted {
  background-color: get-color('grey', hover);
}

.ListItem--highlightOnHover:hover {
  @extend .ListItem--highlighted;
}

.ListItem > *:not(:first-child) {
  margin-left: 8px;
}

.ListItem--selected {
  font-weight: 500;
}

.ListItem--disabled {
  color: $ListItem-textColorDisabled;
  cursor: not-allowed;
}
