html,
body {
  margin: 0;
  height: 100%;
}
body {
  background-color: $color-white;
  overflow-x: hidden;
}

div {
  user-select: none;
}

div:focus {
  user-select: none;
  outline: none;
}

// @media screen and (max-width: 1440px) {
// 	html {
// 		zoom:0.9;
// 	}
// }
