.Chip {
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  color: inherit;
  text-align: left;
  font: inherit;
  user-select: none;
  cursor: pointer;
  outline: none;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  @include typography(body);
  cursor: initial;
  transition: get-animation(background-color, controllers, out),
    get-animation(color, controllers, out);
}

.Chip--medium {
  height: 32px;
  padding: 0 14px;
}

.Chip--small {
  height: 24px;
  padding: 0 10px;
}

.Chip--clickable {
  cursor: pointer;
  user-select: none;
}

.Chip--active {
  transition: get-animation(background-color, controllers, in);
}

.Chip > *:not(:first-child) {
  margin-left: 8px;
}

.Chip__removeIcon {
  flex: none;
  cursor: pointer;
  color: get-color('black', base);
  opacity: 0.3;
}

.Chip__icon {
  flex: none;
}

.Chip__label {
  flex: 1;
}

.Chip__richContent {
  flex: 1;
  min-width: 0;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

@import 'colors/green';
@import 'colors/grey';
@import 'colors/light-grey';
@import 'colors/red';
@import 'colors/white';
@import 'colors/yellow';
@import 'colors/purple';
