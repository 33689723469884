.tags {
  &__tag-item {
    padding: 6px 12px;
    border-radius: 4em;
    background-color: $color-mid-grey;
    color: white;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-align: center;
    text-transform: uppercase;
    margin-right: 1em;
    box-sizing: border-box;

    &-more {
      color: $color-mid-grey;
      background-color: transparent;
      border: 2px solid $color-mid-grey;
      padding: 2px 8px;
    }
  }
}
