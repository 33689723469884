.Chip--grey {
  background-color: get-color('grey', light);
  color: get-color('white', base);
}

.Chip--grey--active,
.Chip--grey--clickable:hover,
.Chip--grey:focus {
  background-color: mix(
    get-color('white', base),
    get-color('grey', light),
    30%
  );
}
