.Chip--red {
  background-color: get-color('red', light);
  color: get-color('white', base);
}

.Chip--red--active,
.Chip--red--clickable:hover,
.Chip--red:focus {
  background-color: mix(get-color('white', base), get-color('red', light), 30%);
}
