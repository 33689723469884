.Adornment {
  min-width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.Adornment--button {
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  color: inherit;
  text-align: left;
  font: inherit;
  user-select: none;
  cursor: pointer;
  outline: none;
}

.Adornment--button:hover,
.Adornment--button:focus {
  opacity: 0.8;
}

.Adornment--button:disabled {
  opacity: 1;
  cursor: not-allowed;
}
