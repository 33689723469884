.hyperworld__grid-container,
.hyperworld__grid {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-auto-rows: min-content;
}

.hyperworld__grid-container {
  box-sizing: border-box;
  padding: 0 24px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  &--study {
    margin-left: 280px;
    width: calc(100% - 280px);
  }
}

.hyperworld__grid {
  grid-template-columns: repeat(12, 1fr);
}

.col-1 {
  grid-column: 1 span;
}

.col-2 {
  grid-column: 2 span;
}

.col-3 {
  grid-column: 3 span;
}

.col-4 {
  grid-column: 4 span;
}

.col-5 {
  grid-column: 5 span;
}

.col-6 {
  grid-column: 6 span;
}

.col-7 {
  grid-column: 7 span;
}

.col-8 {
  grid-column: 8 span;
}

.col-9 {
  grid-column: 9 span;
}

.col-10 {
  grid-column: 10 span;
}

.col-11 {
  grid-column: 11 span;
}

.col-12 {
  grid-column: 12 span;
}

@media screen and (max-width: 1440px) {
  .hyperworld__grid-container {
    margin-left: 80px;
    width: calc(100% - 80px);
    &--studies-list {
      margin-left: 0;
      width: 100%;
    }
  }
}
