@font-face {
  font-family: 'icons';
  src: url('../../assets/icons/icons.eot');
  src: url('../..//assets/icons/icons.eot?#iefix') format('eot'),
    url('../../assets/icons/icons.woff') format('woff'),
    url('../../assets/icons/icons.ttf') format('truetype'),
    url('../../assets/icons/icons.svg#icons') format('svg');
}

.icon:before {
  font-family: 'icons';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.icon-add:before {
  content: '\E001';
}

.icon-bubble:before {
  content: '\E002';
}

.icon-bulb:before {
  content: '\E003';
}

.icon-calendar:before {
  content: '\E004';
}

.icon-carret-down:before {
  content: '\E005';
}

.icon-checkbox:before {
  content: '\E006';
}

.icon-chevron-down:before {
  content: '\E007';
}

.icon-chevron-left:before {
  content: '\E008';
}

.icon-chevron-right:before {
  content: '\E009';
}

.icon-chevron-up:before {
  content: '\E00A';
}

.icon-close-round:before {
  content: '\E00B';
}

.icon-close:before {
  content: '\E00C';
}

.icon-details:before {
  content: '\E00D';
}

.icon-download:before {
  content: '\E00E';
}

.icon-edit:before {
  content: '\E00F';
}

.icon-expert:before {
  content: '\E010';
}

.icon-filter:before {
  content: '\E011';
}

.icon-history:before {
  content: '\E012';
}

.icon-home:before {
  content: '\E013';
}

.icon-like-e:before {
  content: '\E014';
}

.icon-like-f:before {
  content: '\E015';
}

.icon-link:before {
  content: '\E016';
}

.icon-list:before {
  content: '\E017';
}

.icon-lock:before {
  content: '\E018';
}

.icon-login:before {
  content: '\E019';
}

.icon-pause:before {
  content: '\E01A';
}

.icon-play:before {
  content: '\E01B';
}

.icon-rename:before {
  content: '\E01C';
}

.icon-reverse:before {
  content: '\E01D';
}

.icon-save:before {
  content: '\E01E';
}

.icon-score-down:before {
  content: '\E01F';
}

.icon-score-up:before {
  content: '\E020';
}

.icon-scores:before {
  content: '\E021';
}

.icon-search:before {
  content: '\E022';
}

.icon-selection-add:before {
  content: '\E023';
}

.icon-selection-delete:before {
  content: '\E024';
}

.icon-selection:before {
  content: '\E025';
}

.icon-settings:before {
  content: '\E026';
}

.icon-sort-asc:before {
  content: '\E027';
}

.icon-sort-default:before {
  content: '\E028';
}

.icon-sort-desc:before {
  content: '\E029';
}

.icon-tag:before {
  content: '\E02A';
}

.icon-trash:before {
  content: '\E02B';
}

.icon-user:before {
  content: '\E02C';
}

.icon-users:before {
  content: '\E02D';
}
