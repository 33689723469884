.row__item {
  box-sizing: border-box;
  width: 100%;
  height: 41px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: $color-white;
  border-top: 1px solid $color-shadow-grey;
  display: flex;
  align-items: center;
  border-left: 4px solid $color-white;
  transition-property: border, background-colo;
  transition-duration: get-animation(speed, controllers);
  transition-timing-function: get-animation(ease, default);
  text-decoration: none;

  &-study-row {
    height: 60px;
    font-size: 14px;

    &:last-child {
      border-bottom: 1px solid $color-shadow-grey;
    }

    &:hover * {
      color: $color-blue;
    }
  }

  &--selected {
    border-left: 4px solid $color-blue;
  }

  &--deleting {
    border-left: 4px solid transparent;
    background-color: $color-hover;

    * {
      opacity: 0.5;
      color: $color-mid-grey !important;
    }
  }

  &__info {
    // Common to all lists (Rank, Artists, Title)
    display: flex;
    text-transform: capitalize;
    color: $color-dark-grey;

    &-details {
      // Specific Repartition For rank & details tables
      flex: 4;
      min-width: 0;
    }

    &-history {
      // Specific Repartition For History and All Titles
      flex: 4;
      min-width: 0;
    }

    &__rank {
      font-family: 'Fjalla One';
      width: 25px;
      min-width: 25px;
      text-align: center;
      line-height: 41px;
    }
  }

  &__values {
    // Complex tables like details, analytics or expert
    align-items: center;
    display: inline-flex;

    &-details {
      flex: 9;
    }

    &-study {
      flex: 4;
      line-height: 60px;
    }

    &-value {
      text-align: center;
      font-size: 14px;
      flex: 1;

      &-best {
        font-weight: 600;
        color: $color-green;
      }

      &-good {
        font-weight: 600;
        color: $color-green-light;
      }

      &-bad {
        font-weight: 600;
        color: $color-orange;
      }

      &-worst {
        font-weight: 600;
        color: $color-red;
      }

      &-first {
        font-weight: 700 !important;
        color: $color-blue;
      }
    }

    &__study {
      color: $color-black;
      text-align: center;

      &-type {
        @extend .row__item__values__study;
        flex: 1;
        text-transform: capitalize;
      }

      &-waves {
        @extend .row__item__values__study;
        flex: 1;
      }

      &-date {
        @extend .row__item__values__study;
        flex: 1;
      }
    }
  }

  &__progress {
    // Ranking & Versus progress bars
    display: inline-flex;
    align-items: flex;
    flex: 10;
    padding-left: 15px;
    justify-content: center;
    flex-direction: column;

    &-score {
      height: 12px;
      background-image: linear-gradient(149deg, $color-blue, $color-dust);
      border-radius: 8px;
    }

    &-score-versus {
      height: 8px;
      background-image: linear-gradient(149deg, $color-blue, $color-dust);
      border-radius: 12px;
    }

    &-score-versus ~ &-score-versus,
    &-score-versus ~ &-score-versus {
      height: 8px;
      margin-top: 5px;
      border-radius: 12px;
      background-image: linear-gradient(149deg, $color-gradient-1, $color-dust);
    }
  }

  &__score-value {
    // Ranking values
    flex: 1;
    font-family: 'Fjalla One';
    text-align: right;
    color: $color-blue;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    height: 41px;
    padding-right: 1em;
  }

  &__versus-values {
    // Versus values
    display: flex;
    flex: 2;
    text-align: center;
    align-items: center;
    justify-content: space-around;

    &__score-value {
      @extend .row__item__score-value;
    }

    &__diff {
      @extend .row__item__score-value;
      color: $color-black !important;
      font-family: 'Open Sans';
      font-size: 15px;
    }
  }

  &__score-value + &__score-value {
    // Versus Progress bars auto colo
    color: $color-orange;
  }

  &__tags {
    display: inline-flex;
    flex: 8;
    flex-direction: row-reverse;
    align-content: flex-end;
    align-items: center;
  }

  &-cat {
    margin-top: 1em;
    padding-bottom: 0.5em;
    padding-left: 8px;
    font-size: 12px;
    color: $color-blue;
    letter-spacing: 1.8px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

// .row__item__info__title-fav-full {
//   display: block;
//   padding-left: 1em;
//   color: $color-dark-grey;
//   cursor: pointer;
//   line-height: 39px;

//   &:hover {
//     color: $color-blue;
//   }
// }
