.DropdownList {
  z-index: 600;
  font-size: 14px;
  outline: none;
}

// Give space to the label.
.DropdownList[data-placement^='top'] {
  margin-bottom: 16px;
}

.DropdownList__virtualList {
  width: 100%;
  height: 100%;
}
