@media (min-device-width: 1440px) and (max-device-width: 1600px) {
  .container {
    max-width: 1190px;
    margin: auto 124px;
    grid-template-columns: repeat(12, auto);
  }
}

@media (min-device-width: 1280px) and (max-device-width: 1440px) {
  .container {
    max-width: 1070px;
    margin: auto 106px;
    grid-template-columns: repeat(12, auto);
  }
}

@media (min-device-width: 1600px) {
  .container {
    max-width: 1200px;
    margin: auto 200px;
    grid-template-columns: repeat(12, auto);
  }
}

@media (min-device-width: 1600px) {
  .container {
    max-width: 1200px;
    margin: auto 200px;
    grid-template-columns: repeat(12, auto);
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(12, auto);
  grid-gap: 22px;
}
