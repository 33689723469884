.Chip--green {
  background-color: get-color('green', base);
  color: get-color('white', base);
}

.Chip--green--active,
.Chip--green--clickable:hover,
.Chip--green:focus {
  background-color: mix(
    get-color('white', base),
    get-color('green', base),
    30%
  );
}
